<div *ngIf="!loading" fxFlex fxLayout="row" fxLayoutGap="1vw" fxLayoutAlign="center center">
    <div class="parentGraph" fxFlexFill fxGrow="0" fxLayoutAlign="center center">
        <div class="noGraphMessage" *ngIf="!showGraph">There is no data to populate this graph</div>
        <mat-icon class="customDownload" *ngIf="customDownload.type && showGraph"
            (click)="downloadDataMethod(customDownload.type)" title="{{customDownload['title'] }}">download</mat-icon>
        <!-- [ngStyle]="{'height' : chartHeight}" -->
        <apx-chart [class.noGraphData]="!showGraph" style="width: 100%;" [series]="chartSeries" [chart]="chartDetails"
            [xaxis]="xaxis" [dataLabels]="dataLabels" [title]="title" [labels]="labels" [colors]="colors"
            [tooltip]="tooltip" [plotOptions]="plotOptions" [legend]="legend">
        </apx-chart>
    </div>
    <!-- <div class="parentGraph" fxFlexFill fxGrow="0" fxLayoutAlign="center center" *ngIf="!showGraph">
        There is no data to populate this graph
    </div> -->
</div>