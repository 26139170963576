import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'enum'
})
export class EnumPipe implements PipeTransform {

    transform(value: any, enums: any): string {

        if (enums === undefined || !value) {
            return '';
        }

        if (Array.isArray(value)) {
            const displayNames = value.map(val => {
                return enums.find((i: any) => i.value === val)?.displayName || '';
            }).filter(displayName => displayName);
            return displayNames.join(', ');
        }
        else {
            return enums.find((i: any) => i.value === value)?.displayName;
        }
    }

}
