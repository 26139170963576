<h1 mat-dialog-title *ngIf="title">
  {{ title }}
</h1>

<div mat-dialog-content>
  <p>{{ message }}</p>
  <div *ngIf="agreeRadio">
    <mat-radio-group [(ngModel)]="agreeRadioForm">
      <mat-radio-button value="agree">{{'I Agree' | translate}}</mat-radio-button>
    </mat-radio-group>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-button class="primary" (click)="onDismiss()">
    {{ noButton }}
  </button>
  <button mat-raised-button (click)="onConfirm()" *ngIf="showYesButton">{{ yesButton }}</button>
  <button mat-button class="primary" (click)="onCancel()" *ngIf="cancelButton">
    {{ cancelButton }}
  </button>
</div>