<div class="mainTable">
  <!-- <ng-template>
    <div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px" *ngIf="canSelectFilter" [hidden]="!allowNew">
      <div fxFlex style="line-height: 75px">
        <app-custom-select-autocomplete #customselectautocomplete *ngIf="selectedDatafilters" [value]="selectedDataName"
          [dataType]="dataTypeFilter" [dataTypeDisplay]="dataTypeDisplayFilter" [placeholder]="'Select Filter'"
          [filters]="selectedDatafilters" [canDelete]="false" (onSelectReturn)="setSelectedDataName($event)">
        </app-custom-select-autocomplete>
      </div>
      <div fxFlex></div>
      <div fxFlex></div>
    </div>
  </ng-template> -->
  <div class="mb-20" flFlex flexLayout="row" flexLayout.lt-md="column">
    <div fxFlex>
      <!-- <div class="page-title">
        {{dataTypeDisplay}}
      </div> -->
      <div class="tableHeader">

        <div fxLayout="row" fxFlex class="actions" fxLayoutGap="1vw">
          <div fxFlex fxLayoutAlign="end center" fxGrow="1">
            <mat-form-field class="search-input" appearance="outline">
              <input matInput #searchInput placeholder="{{ 'Search' | translate }}" [value]="searchVal" />
            </mat-form-field>
          </div>
          <div fxFlex fxLayoutAlign="end center" fxGrow="0">
            <div class="topRightBtn">
              <ng-template>
                <a [hidden]="!canFilter" href="Javascript:void(0)" *ngIf="customBubbleFilters.length > 0"
                  (click)="clearFilterIt()">clear filter</a>
                <mat-icon (click)="filterIt()" [hidden]="!canFilter" class="filter" title="Filter">filter_list
                </mat-icon>
                <mat-icon (click)="exportIt()" [hidden]="!canExport" class="filter" title="Export">file_copy</mat-icon>
              </ng-template>


              <div class="table-icons">
                <div class="add-icon" [hidden]="!(canNew && creatable) || disableNew" (click)="create($event)"
                  [title]="'Add New ' + dataTypeDisplay">
                  <mat-icon [hidden]="!(canNew && creatable)">
                    add</mat-icon>Add {{dataTypeDisplay}}
                </div>
                <div class="add-icon" *ngIf="tableSetting && tableSetting['disableCreate'] && !(canNew && creatable)"
                  [matTooltip]="'This feature requires higher permissions'">
                  <mat-icon>
                    add</mat-icon>Add {{dataTypeDisplay}}
                </div>
                <div class="add-icon" *ngIf="canNew && creatable && disableNew"
                  [matTooltip]="'This feature is not available'">
                  <mat-icon>
                    add</mat-icon>Add {{dataTypeDisplay}}
                </div>
                <mat-icon (click)="refresh()" [hidden]="!canRefresh" class="refresh"
                  title="{{ 'Refresh' | translate }}">
                  refresh
                </mat-icon>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div [hidden]="firstLoad || showNoDataMessage" class="topRightBubbles" *ngIf="metaFieldSetting && canFilter">
        <mat-chip-listbox>
          <mat-chip *ngFor="let bb of customBubbleFilters">
            <span *ngIf="!bb.parent">{{ metaFieldSetting[bb.displayName].displayName }}</span>
            <span *ngIf="bb.parent">{{
              metaFieldSetting[bb.parent][bb.displayName].displayName
              }}</span>

            <mat-icon style="cursor: pointer; margin-left: 3px" (click)="removeFilter(bb.name)">
              {{ "Close" | translate }}
            </mat-icon>
          </mat-chip>
        </mat-chip-listbox>
      </div>
      <div [hidden]="firstLoad || showNoDataMessage" #myIdentifier class="example-table-container mat-elevation-z8"
        style="clear: both; border-radius: 15px; box-sizing: border-box;" [class.example-container-preview]="isPreview">
        <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)" [matSortActive]="orderBy"
          [matSortDirection]="orderDir" class="myTable" [class.big-table]="displayedColumns.length > 10"
          [style.max-width.px]="myIdentifier.offsetWidth">
          <!-- ID Column-->
          <ng-container *ngIf="tableSetting">
            <ng-container matColumnDef="{{ col.parent === '' ? col.name : col.parent + '.' + col.name }}"
              *ngFor="let col of tableSetting.fields" [stickyEnd]="col.type === 'action'">
              <th mat-header-cell *matHeaderCellDef mat-sort-header layout-align="start left"
                [class.actions-center]="col.type === 'action'"
                [class]="col.displayName.length > 60 ? 'extra-title' : ''" [disabled]="!col.orderable || !canSort">
                {{ col.displayName | translate }}
              </th>
              <td mat-cell *matCellDef="let objectData; let index = index"
                [style.min-width.px]="col.type === 'image' ? '120':'100'"
                [style.overflow]="col.type === 'action' ? 'visible' : 'hidden'"
                [style.white-space]="col.type === 'action' ? 'break-spaces' : 'nowrap'"
                [style.max-width.px]="col.type === 'action' ? '150' : '150'"
                [style.width.px]="col.type === 'action' ? '150' : 'unset'">
                <span *ngIf="col.type !== 'action'">
                  <span *ngIf="col.parent === ''">
                    <span *ngIf="
                       (objectData[col.name] || col.type == 'boolean') ||
                        objectData[col.name] === 0 ||
                        objectData[col.name] === ''||
                        objectData[col.name] === undefined
                      ">
                      <span *ngIf="normalDisplay.includes(col.type)" title="{{ objectData[col.name] }}">
                        <span *ngIf="col.name === 'name' && objectData.hasOwnProperty('active')"
                          title="{{ objectData[col.name] }}">
                          <mat-icon *ngIf="objectData['active']" class="activeColor">brightness_1</mat-icon>
                          <mat-icon *ngIf="!objectData['active']" class="inactiveColor">brightness_1</mat-icon>
                        </span>
                        <span *ngIf="objectData[col.name]">
                          {{ objectData[col.name] | truncate: ["50", "..."] }}
                        </span>
                      </span>


                      <span class="profile-page-header" *ngIf="col.type === 'image'" title="{{ objectData[col.name] }}">
                        <figure class="card-profile-image">
                          <img style="max-height: 100px; max-width: 100px;" src="{{
                            objectData[col.name] == undefined || objectData[col.name] == ''
                                ? 'assets/images/profile.png'
                                : objectData[col.name]
                            }}" class="circle z-depth-2 responsive-img activator" />
                        </figure>
                      </span>
                      <span *ngIf="col.type === 'datetime'">
                        {{ objectData[col.name] | date: "MM-dd-yyyy hh:mm aa"}}
                      </span>
                      <span *ngIf="col.type === 'date'">
                        {{ objectData[col.name] | date: "mediumDate" }}
                      </span>

                      <span *ngIf="col.type === 'boolean'">

                        <span *ngIf="dataType === 'address'">
                          <mat-slide-toggle color="primary" title="Set as primary location"
                            [checked]="objectData[col.name]" (change)="changePrimaryAddress(objectData._id,$event)">
                          </mat-slide-toggle>
                        </span>
                        <span *ngIf="dataType !== 'address'">
                          <span *ngIf="objectData[col.name]">True</span>
                          <span *ngIf="!objectData[col.name]">False</span>
                        </span>
                      </span>
                      <span *ngIf="col.type === 'reference'">
                        <span *ngIf="col.reference.kind === 'single'">{{ objectData[col.name]["name"] }}
                          <span *ngIf="col.name === 'program' && objectData['phd']">PhD</span>
                          <span *ngIf="col.name === 'diagnosticId' && objectData[col.name].length > 0">
                            <span *ngFor="let fld of objectData[col.name]; let subindex = index">
                              {{ fld.name }}<span *ngIf="subindex < objectData[col.name].length - 1"><br></span>
                            </span>
                          </span>
                        </span>
                        <span
                          *ngIf="col.reference.kind === 'multiple' && col.name == 'questions'  && col.name !== 'resources'">
                          <span *ngFor="let fld of objectData[col.name]; let subindex = index">{{ fld.name
                            }}<span *ngIf="subindex < objectData[col.name].length - 1">,
                            </span></span>
                        </span>
                        <span
                          *ngIf="col.reference.kind === 'multiple' && col.name !== 'resources' && col.name !== 'questions' && col.name !== 'tiles'">
                          <span *ngFor="let fld of objectData[col.name]; let subindex = index">{{ fld.name
                            }}<span *ngIf="subindex < objectData[col.name].length - 1">,
                            </span></span>
                        </span>
                        <span *ngIf="col.reference.kind === 'multiple' && col.name === 'resources'">
                          <span *ngFor="let fld of objectData[col.name]; let subindex = index">{{ fld.name
                            }}<span *ngIf="subindex < objectData[col.name].length - 1">,
                            </span></span>
                        </span>
                        <span *ngIf="col.reference.kind === 'multiple' && col.name === 'tiles'">
                          <span *ngFor="let fld of objectData[col.name]; let subindex = index">{{ fld.title
                            }}<span *ngIf="subindex < objectData[col.name].length - 1">,
                            </span></span>
                        </span>
                      </span>
                      <span *ngIf="col.type === 'arrayreference'">
                        <span *ngFor="let fld of objectData[col.name]; let subindex = index">{{ fld.name
                          }}<span *ngIf="subindex < objectData[col.name].length - 1">, </span></span>
                      </span>
                      <span *ngIf="col.type === 'array'">
                        <span *ngFor="let fld of objectData[col.name]; let subindex = index">{{ fld.name
                          }}<span *ngIf="subindex < objectData[col.name].length - 1">, </span></span>
                      </span>
                      <span *ngIf="col.type === 'objectjson' && col.hasOwnProperty('isHost')">
                        <span *ngFor="let fld of objectData[col.name]; let subindex = index"><span
                            *ngIf="col.isHost === fld.host">{{ fld.name }}</span></span>
                      </span>
                      <span *ngIf="col.type === 'enum'">
                        <app-enum-view [enumValue]="objectData[col.name]" [enumList]="col.enum"></app-enum-view>
                      </span>
                      <span *ngIf="col.type === 'enumEmoji'">
                        <div *ngIf="col.enum[objectData[col.name] - 1] != undefined" style="
                            float: left;
                            width: 30px;
                            height: 30px;
                            background-size: contain;
                            margin-right: 10px;
                            margin-left: 50px;
                          " class="{{ col.enum[objectData[col.name] - 1].emoji }}"></div>
                        <span *ngIf="col.enum[objectData[col.name] - 1] != undefined" style="line-height: 30px">{{
                          col.enum[objectData[col.name] - 1].displayName }}</span>
                      </span>
                      <span *ngIf="col.type === 'tags'">
                        <mat-chip-listbox aria-label="Tags">
                          <mat-chip *ngFor="let tg of objectData[col.name]">{{ tg.name }}</mat-chip>
                        </mat-chip-listbox>
                      </span>
                    </span>
                  </span>
                  <span *ngIf="col.parent !== ''">
                    <span *ngIf="objectData[col.parent] && objectData[col.parent][col.name]">
                      <span *ngIf="normalDisplay.includes(col.type)" title="{{ objectData[col.parent][col.name] }}">
                        {{ objectData[col.parent][col.name] | truncate: ["50", "..."] }}
                      </span>

                      <span *ngIf="col.type === 'textarea'" title="{{ objectData[col.parent][col.name] }}">
                        {{ objectData[col.parent][col.name] | truncate: ["50", "..."] }}
                      </span>
                      <span *ngIf="col.type === 'textareaSpan'" title="{{ objectData[col.parent][col.name] }}">
                        {{ objectData[col.parent][col.name] | truncate: ["50", "..."] }}
                      </span>
                      <span *ngIf="col.type === 'datetime'">
                        {{ objectData[col.parent][col.name] | date: "mediumDate":"UTC" }}
                      </span>
                      <span *ngIf="col.type === 'date'">
                        {{ objectData[col.parent][col.name] | date: "mediumDate":"UTC" }}
                      </span>
                      <span *ngIf="col.type === 'boolean'">
                        <span *ngIf="objectData[col.parent][col.name]">True</span>
                        <span *ngIf="!objectData[col.parent][col.name]">False</span>
                      </span>
                      <span *ngIf="col.type === 'reference'">
                        <span *ngIf="col.reference.kind === 'single'">{{
                          objectData[col.parent][col.name]["name"]
                          }}</span>
                        <span *ngIf="col.reference.kind === 'multiple'">
                          <span *ngFor="
                              let fld of objectData[col.parent][col.name];
                              let subindex = index
                            ">{{ fld.name
                            }}<span *ngIf="subindex < objectData[col.parent][col.name].length - 1">,
                            </span></span>
                        </span>
                      </span>
                      <span *ngIf="col.type === 'arrayreference'">
                        <span *ngFor="let fld of objectData[col.parent][col.name]; let subindex = index">{{ fld.name
                          }}<span *ngIf="subindex < objectData[col.parent][col.name].length - 1">,
                          </span></span>
                      </span>
                      <span *ngIf="col.type === 'objectjson' && col.hasOwnProperty('isHost')">
                        <span *ngFor="let fld of objectData[col.parent][col.name]; let subindex = index"><span
                            *ngIf="col.isHost === fld.host">{{ fld.name }}</span></span>
                      </span>
                      <span *ngIf="col.type === 'enum'">
                        <app-enum-view [enumValue]="objectData[col.parent][col.name]" [enumList]="col.enum">
                        </app-enum-view>
                      </span>
                      <span *ngIf="col.type === 'tags'">
                        <mat-chip-listbox aria-label="Tags">
                          <mat-chip *ngFor="let tg of objectData[col.parent][col.name]">{{
                            tg.name
                            }}</mat-chip>
                        </mat-chip-listbox>
                      </span>
                    </span>
                  </span>
                </span>
                <span *ngIf="col.type === 'action'">
                  <span *ngIf="col.dataType === 'array'">
                    <span *ngFor="let act of col.actions">
                      <span *ngIf="act.hasOwnProperty('rule')">
                        <span *ngIf="objectData[act.rule[0]] == act.rule[1]">
                          <!-- <span>{{act|json}}</span> -->
                          <span *ngIf="act.condition">
                            <button (click)="toggleClick(act.action, act.target, objectData, index)" mat-icon-button
                              color="primary" title="{{ act.displayName }}">
                              <mat-icon>{{ act.icon }}</mat-icon>
                            </button>
                          </span>
                        </span>
                      </span>
                      <span *ngIf="!act.hasOwnProperty('rule')">

                        <span *ngIf="act.hasOwnProperty('condition')">
                          <span *ngIf="act.condition.hasOwnProperty('parent')">
                            <button *ngIf="act.icon == '' && act.imageUrl != undefined"
                              (click)="toggleClick(act.action, act.target, objectData, index)" mat-icon-button
                              [disabled]="objectData[act.condition.parent][act.condition.current] !== ''"
                              color="primary" title="{{ act.displayName }}">
                              <img src="{{act.imageUrl}}" />

                            </button>
                            <button *ngIf="act.icon != ''"
                              (click)="toggleClick(act.action, act.target, objectData, index)" mat-icon-button
                              [disabled]="objectData[act.condition.current] !== ''" color="primary"
                              title="{{ act.displayName }}">
                              <mat-icon>{{ act.icon }}</mat-icon>
                            </button>
                          </span>
                          <span *ngIf="!act.condition.hasOwnProperty('parent')">
                            <button *ngIf="act.icon == '' && act.imageUrl != undefined"
                              (click)="toggleClick(act.action, act.target, objectData, index)" mat-icon-button
                              [disabled]="objectData[act.condition.current] !== ''" color="primary"
                              title="{{ act.displayName }}">
                              <img src="{{act.imageUrl}}" />

                            </button>
                            <button *ngIf="act.icon != ''"
                              (click)="toggleClick(act.action, act.target, objectData, index)" mat-icon-button
                              [disabled]="objectData[act.condition.current] !== ''" color="primary"
                              title="{{ act.displayName }}">
                              <mat-icon>{{ act.icon }}</mat-icon>
                            </button>
                          </span>
                        </span>
                        <span *ngIf="!act.hasOwnProperty('condition')" class="tools">
                          <button *ngIf="act.icon == '' && act.imageUrl != undefined"
                            (click)="toggleClick(act.action, act.target, objectData, index)" mat-icon-button
                            color="primary" title="{{ act.displayName }}">
                            <img src="{{act.imageUrl}}" />

                          </button>

                          <mat-icon *ngIf="act.icon != ''"
                            [matTooltip]="act.disabled ? 'This feature requires higher permissions' : act.displayName | translate"
                            (click)="toggleClick(act.action, act.target, objectData, index, act.disabled)">{{ act.icon
                            }}
                          </mat-icon>
                        </span>
                      </span>
                    </span>
                  </span>
                  <span *ngIf="col.dataType !== 'array'">
                    <button *ngIf="editable && col.dataType !== 'button' && col.action === 'edit'"
                      (click)="toggleClick(col.action, col.target, objectData, index)" mat-icon-button color="primary"
                      matTooltip="Edit">
                      <mat-icon>create</mat-icon>
                    </button>
                    <button *ngIf="col.dataType !== 'button' && col.action === 'view'"
                      (click)="toggleClick(col.action, col.target, objectData, index)" mat-icon-button color="primary"
                      matTooltip="Edit">
                      <mat-icon>open_in_browser</mat-icon>
                      <!-- <mat-icon>web_asset</mat-icon> -->
                    </button>
                    <button *ngIf="deletable && col.dataType !== 'button' && col.action === 'delete'" mat-icon-button
                      color="primary" matTooltip="Delete" type="button"
                      (click)="toggleClick(col.action, col.target, objectData, index)">
                      <mat-icon>delete</mat-icon>
                    </button>
                    <!-- <button *ngIf="col.dataType !== 'button' && (col.action !== 'view' && col.action !== 'delete' && col.action !== 'edit')" mat-icon-button (click)="toggleClick(col.action, col.target, objectData, index)" [disabled]="loading"><mat-icon>create</mat-icon></button> -->
                    <!-- <button *ngIf="col.dataType === 'button'"  class="btn btn-xs" [class.btn-warning]="!objectData[col.sortid]" [class.btn-success]="objectData[col.sortid]" (click)="toggleClick(col.action, col.target, objectData, index)" [disabled]="loading">{{col.name}}</button> -->
                  </span>
                </span>
              </td>
            </ng-container>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <mat-progress-spinner [diameter]="30" *ngIf="loading" class="mat-spinner-color" color="warn"
          mode="indeterminate">
        </mat-progress-spinner>
      </div>
      <div class="mat-table__message" [hidden]="firstLoad || showNoDataMessage">
        <span *ngIf="!loading && tableDataTotal === 0">{{ "No records found" | translate }}</span>
      </div>
      <mat-paginator [hidden]="firstLoad || showNoDataMessage || !pagination" class="paginator"
        [pageIndex]="pageNumber - 1" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25, 100]"
        [length]="paginatorTotal | async" [showFirstLastButtons]="true">
      </mat-paginator>
    </div>
    <!-- end::Body -->
  </div>
</div>

<div class="loading-message" [hidden]="!firstLoad">
  Loading...
</div>
<div class="no-data-message" [hidden]="!showNoDataMessage">
  {{NoDataMessage}}
</div>